<template>
  <div class="form-helper-factory-data container">
    <div class="row">
      <div class="col-6">
        <div class="h5 mb-3">{{ $t("formHelperFactory.formJson") }}</div>
        <Code :default-value="valueJson" :field="codeFieldJson" />
        <div class="mt-3">
          <div class="btn btn-primary" @click="copy(valueJson)">
            <i class="fal fa-copy" />
            {{ $t("formHelperFactory.copyJson") }}
          </div>
          <div
            class="btn btn-primary ml-1"
            @click="download(valueJson, 'json')"
          >
            <i class="fal fa-download" />
            {{ $t("formHelperFactory.exportJson") }}
          </div>
        </div>
      </div>
      <div class="col-6">
        <div class="h5 mb-3">{{ $t("formHelperFactory.formYaml") }}</div>
        <Code :default-value="valueYaml" :field="codeFieldYaml" />
        <div class="mt-3">
          <div class="btn btn-primary" @click="copy(valueYaml)">
            <i class="fal fa-copy" />
            {{ $t("formHelperFactory.copyYaml") }}
          </div>
          <div class="btn btn-primary ml-1" @click="download(valueYaml, 'yml')">
            <i class="fal fa-download" />
            {{ $t("formHelperFactory.exportYaml") }}
          </div>
        </div>
      </div>
      <div class="col-6">
        <div class="h5 mb-3">
          {{ $t("formHelperFactory.formHelperConfig") }}
        </div>
        <Code :default-value="valueOptions" :field="codeFieldJson" />
        <div class="mt-3">
          <div class="btn btn-primary" @click="copy(valueOptions)">
            <i class="fal fa-copy" />
            {{ $t("formHelperFactory.copyJson") }}
          </div>
          <div
            class="btn btn-primary ml-1"
            @click="download(valueOptions, 'json')"
          >
            <i class="fal fa-download" />
            {{ $t("formHelperFactory.exportJson") }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import Code from "@/components/Tools/FormHelper/Fields/Code.vue";
import { UPDATE_FORM } from "@/core/services/store/formHelperFactory.module";
import jsYaml from "js-yaml";
import { copyToClipboard } from "@/components/Tools/helperFunctions";
import { compressForm } from "@/components/Tools/FormHelperFactory/Helper/functions";
import _ from "lodash";

export default {
  components: { Code },
  props: {},
  data() {
    return {
      codeFieldJson: {
        lang: "json",
        plain: true,
        disabled: true
      },
      codeFieldYaml: {
        lang: "yaml",
        plain: true,
        disabled: true
      }
    };
  },
  computed: {
    ...mapGetters("formHelperFactory", ["form", "options"]),
    compressedForm: function () {
      let form = _.cloneDeep(compressForm(this.form));
      form.forEach(f => delete f.id);
      return form;
    },
    valueJson: function () {
      return JSON.stringify(this.compressedForm, null, 2);
    },
    valueYaml: function () {
      return jsYaml.dump(this.compressedForm);
    },
    valueOptions: function () {
      return JSON.stringify(this.options, null, 2);
    }
  },
  mounted() {},
  methods: {
    ...mapActions("formHelperFactory", [UPDATE_FORM]),
    copy(value) {
      copyToClipboard(value);
    },
    download(value, fileType) {
      let element = document.createElement("a");
      element.setAttribute(
        "href",
        "data:text/plain;charset=utf-8," + encodeURIComponent(value)
      );
      element.setAttribute("download", "data." + fileType);

      element.style.display = "none";
      document.body.appendChild(element);

      element.click();

      document.body.removeChild(element);
    }
  }
};
</script>

<style lang="scss" scoped>
.form-helper-factory-data {
  :deep(.CodeMirror) {
    resize: vertical;
    min-height: 350px;
    height: 40vh;
  }
}
</style>

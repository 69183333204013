<template>
  <div class="h-100">
    <Factory class="form-helper-factory" />
  </div>
</template>

<script>
import Factory from "@/components/Tools/FormHelperFactory/Components/Factory.vue";

export default {
  components: { Factory },
  props: {},
  data() {
    return {};
  },
  computed: {},
  mounted() {},
  methods: {}
};
</script>

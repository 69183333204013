import { base } from "@/components/Tools/FormHelperFactory/Configurations/baseConfigurations";
import { defaultOperators } from "@/components/Tools/FormHelper/Helper/constants";

export const condition = {
  ...base,
  operators: {
    type: "array",
    default: defaultOperators
  }
};

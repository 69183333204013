import {
  base,
  validations
} from "@/components/Tools/FormHelperFactory/Configurations/baseConfigurations";
import i18n from "@/core/plugins/vue-i18n";

export const image = {
  ...base,
  ...validations,
  ratio: {
    type: "number",
    default: 1
  },
  dropZoneLabel: {
    type: "text",
    strict: true,
    default: i18n.t("formHelper.dropZoneLabel")
  },
  uploadLabel: {
    type: "text",
    strict: true,
    default: i18n.t("formHelper.uploadLabel")
  },
  defaultImage: {
    type: "text",
    strict: true,
    default: ""
  },
  container: {
    type: "text",
    strict: true,
    default: ""
  }
};

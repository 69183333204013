import {
  base,
  select as selectConfig,
  validations
} from "@/components/Tools/FormHelperFactory/Configurations/baseConfigurations";

export const select = {
  ...base,
  ...selectConfig,
  ...validations
};

<template>
  <div class="h-100 d-flex flex-column">
    <div class="h5 font-weight-bolder mb-5">
      {{ $t("formHelperFactory.structure") }}
    </div>
    <div
      class="position-relative flex-grow-1"
      :class="{ 'dropzone border rounded p-3 m-n3': !structure.length }"
      :style="
        structure.length
          ? ''
          : 'border-style: dashed !important; height: ' + minHeight + 'px;'
      "
    >
      <div
        v-if="!structure.length"
        class="dropzone-label position-absolute text-muted h5 my-0"
      >
        {{ $t("formHelperFactory.dropZoneLabel") }}
      </div>
      <draggable
        v-model="structure"
        group="factory"
        handle=".handle"
        class="h-100"
        :empty-insert-threshold="0"
        v-bind="dragOptions"
        @start="drag = true"
        @end="drag = false"
      >
        <transition-group
          type="transition"
          :name="!drag ? 'flip-list' : null"
          class="list-group h-100"
        >
          <div
            v-for="(field, i) in structure"
            :key="field.id"
            class="list-group-item d-flex align-items-center justify-content-start"
          >
            <div class="mr-3 line-height-0">
              <i class="fal fa-bars handle cursor-move" />
            </div>
            <div class="flex-grow-1">
              {{ field.label }}
              <span class="text-muted"> - {{ field.type }}</span>
            </div>
            <div
              class="btn rounded-circle text-hover-danger ml-1"
              @click="removeField(i)"
            >
              <i class="fal fa-xmark cursor-pointer pr-0" />
            </div>
          </div>
        </transition-group>
      </draggable>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { UPDATE_FORM } from "@/core/services/store/formHelperFactory.module";

export default {
  components: {},
  props: {},
  data() {
    return {
      minHeight: 200,
      drag: false,
      dragOptions: {
        animation: 200,
        group: "description",
        disabled: false,
        ghostClass: "ghost"
      }
    };
  },
  computed: {
    ...mapGetters("formHelperFactory", ["form"]),
    structure: {
      get: function () {
        return this.form;
      },
      set: function (value) {
        this[UPDATE_FORM](value);
      }
    }
  },
  mounted() {},
  methods: {
    ...mapActions("formHelperFactory", [UPDATE_FORM]),
    removeField(index) {
      let form = this.structure;
      form.splice(index, 1);
      this[UPDATE_FORM](form);
    }
  }
};
</script>

<style lang="scss" scoped>
.dropzone-label {
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.form-helper-factory {
  .flip-list-move {
    transition: transform 0.5s;
  }
  .ghost {
    opacity: 0.5;
    background: var(--light);
  }
}
</style>

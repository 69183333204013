import i18n from "@/core/plugins/vue-i18n";
import {
  locatorValidators,
  numberValidators,
  simpleValidators,
  singleParameterValidators
} from "@/components/Tools/FormHelper/Helper/constants";

export const minimum = {
  type: {
    type: "hidden",
    required: true
  },
  name: {
    type: "text",
    required: true,
    strict: true
  },
  label: {
    type: "text",
    strict: true,
    required: false
  },
  dependsOn: {
    type: "group",
    buttonLabel: i18n.t("formHelperFactory.dependsOnButtonLabel"),
    default: [],
    fields: [
      {
        type: "select",
        name: "name",
        label: "name",
        options: []
      },
      {
        type: "array",
        name: "values",
        label: "values"
      },
      {
        type: "array",
        name: "notValues",
        label: "notValues"
      }
    ]
  }
};

export const base = {
  ...minimum,
  help: {
    type: "text",
    strict: true,
    required: false,
    default: ""
  },
  disabled: {
    type: "checkbox",
    required: false,
    default: false
  }
};

export const input = {
  ...base,
  placeholder: {
    type: "text",
    strict: true,
    required: false,
    default: ""
  },
  prepend: {
    type: "text",
    default: "",
    strict: true
  },
  prependIcon: {
    type: "text",
    default: "",
    strict: true
  },
  append: {
    type: "text",
    default: "",
    strict: true
  },
  appendIcon: {
    type: "text",
    default: "",
    strict: true
  },
  copy: {
    type: "checkbox",
    default: false
  }
};

export const requireable = {
  required: {
    type: "checkbox",
    default: false
  }
};

export const validations = {
  ...requireable,
  validations: {
    type: "group",
    buttonLabel: i18n.t("formHelperFactory.validationsButtonLabel"),
    default: [],
    fields: [
      {
        type: "select",
        name: "validator",
        label: "validator",
        options: [
          ...simpleValidators,
          ...numberValidators,
          ...singleParameterValidators,
          ...locatorValidators
          // "between",
          // "or",
          // "and"
        ]
      },
      {
        type: "text",
        name: "param",
        label: "param",
        strict: true,
        dependsOn: [
          {
            name: "validator",
            values: [...singleParameterValidators, ...locatorValidators]
          }
        ]
      },
      // {
      //   type: "text",
      //   name: "locator",
      //   label: "locator",
      //   strict: true,
      //   dependsOn: [
      //     {
      //       name: "validator",
      //       values: locatorValidators
      //     }
      //   ]
      // },
      {
        type: "checkbox",
        name: "not",
        label: "not",
        default: false
      }
    ]
  }
};

export const select = {
  clearable: {
    type: "checkbox",
    default: false
  },
  options: {
    type: "array",
    default: []
  },
  sort: {
    type: "select",
    options: ["none", "asc", "desc"],
    clearable: true,
    default: "none"
  }
};

export const colors = [
  "primary",
  "secondary",
  "success",
  "danger",
  "warning",
  "info"
];

export const languages = ["php", "js", "json", "yaml", "sql", "twig"];

<template>
  <div class="card card-custom h-100">
    <div class="card-header border-bottom-0 mb-n8">
      <div class="card-title zindex-1">
        {{ $t("formHelperFactory.factory") }}
      </div>
      <div class="card-toolbar">
        <div class="zindex-1">
          <div class="btn btn-hover-secondary">
            {{ $t("general.back") }}
          </div>
          <div class="btn btn-primary ml-1" @click="load">
            {{ $t("formHelperFactory.load") }}
          </div>
          <div class="btn btn-primary ml-1" @click="save">
            {{ $t("general.save") }}
          </div>
        </div>
      </div>
    </div>
    <div class="card-body pt-0">
      <b-tabs
        align="center"
        class="h-100"
        content-class="h-100"
        @input="onTabChanged"
      >
        <b-tab
          :title="$t('formHelperFactory.structure')"
          class="h-100 container-fluid py-3 mx-0"
          title-item-class="my-0 mx-1"
        >
          <div class="row h-100 align-items-stretch">
            <div class="col-3 border-right h-100">
              <div class="p-5 h-100">
                <Library />
              </div>
            </div>
            <div class="col-9">
              <div class="p-5 h-100">
                <Structure />
              </div>
            </div>
          </div>
        </b-tab>
        <b-tab
          :title="$t('formHelperFactory.configuration')"
          class="p-5 h-100"
          title-item-class="my-0 mx-1"
        >
          <div class="d-flex flex-column h-100">
            <div class="p-5 overflow-auto" style="flex: 1">
              <Form />
            </div>
            <hr />
            <div class="py-5 overflow-y-auto" style="flex: 1">
              <Configuration />
            </div>
          </div>
        </b-tab>
        <b-tab
          :title="$t('formHelperFactory.data')"
          class="p-5"
          title-item-class="my-0 mx-1"
          @click="dataKey++"
        >
          <DataView :key="dataKey" />
        </b-tab>
      </b-tabs>
      <ModalSave ref="modalSave" />
      <ModalLoad ref="modalLoad" />
    </div>
  </div>
</template>

<script>
import Form from "@/components/Tools/FormHelperFactory/Components/Form.vue";
import Configuration from "@/components/Tools/FormHelperFactory/Components/Configuration.vue";
import DataView from "@/components/Tools/FormHelperFactory/Components/Data.vue";
import Library from "@/components/Tools/FormHelperFactory/Components/Library.vue";
import Structure from "@/components/Tools/FormHelperFactory/Components/Structure.vue";
import ModalSave from "@/components/Tools/FormHelperFactory/Components/Modals/ModalSave.vue";
import ModalLoad from "@/components/Tools/FormHelperFactory/Components/Modals/ModalLoad.vue";
import { mapActions } from "vuex";
import { SET_ACTIVE_FIELD } from "@/core/services/store/formHelperFactory.module";
export default {
  components: {
    ModalLoad,
    ModalSave,
    Structure,
    Library,
    Form,
    Configuration,
    DataView
  },
  props: {},
  data() {
    return {
      dataKey: 1
    };
  },
  computed: {},
  mounted() {},
  methods: {
    ...mapActions("formHelperFactory", [SET_ACTIVE_FIELD]),
    save() {
      this.$refs.modalSave.show();
    },
    load() {
      this.$refs.modalLoad.show();
    },
    onTabChanged() {
      this[SET_ACTIVE_FIELD](null);
    }
  }
};
</script>

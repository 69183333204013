import { minimum } from "@/components/Tools/FormHelperFactory/Configurations/baseConfigurations";
import { colors } from "@/components/Tools/FormHelper/Helper/constants";

export const alert = {
  ...minimum,
  color: {
    type: "select",
    options: colors,
    default: "primary"
  },
  icon: {
    type: "text",
    default: ""
  }
};

import {
  base,
  languages,
  validations
} from "@/components/Tools/FormHelperFactory/Configurations/baseConfigurations";

export const code = {
  ...base,
  ...validations,
  lang: {
    type: "select",
    options: languages,
    default: "php"
  },
  tabSize: {
    type: "number",
    default: 4,
    validations: {
      integer: true
    }
  }
};

import { action } from "@/components/Tools/FormHelperFactory/Configurations/Action";
import { alert } from "@/components/Tools/FormHelperFactory/Configurations/Alert";
import { array } from "@/components/Tools/FormHelperFactory/Configurations/Array";
import { checkbox } from "@/components/Tools/FormHelperFactory/Configurations/Checkbox";
import { code } from "@/components/Tools/FormHelperFactory/Configurations/Code";
import { condition } from "@/components/Tools/FormHelperFactory/Configurations/Condition";
import { date } from "@/components/Tools/FormHelperFactory/Configurations/Date";
import { datetime } from "@/components/Tools/FormHelperFactory/Configurations/DateTime";
import { file } from "@/components/Tools/FormHelperFactory/Configurations/File";
import { filter } from "@/components/Tools/FormHelperFactory/Configurations/Filter";
import { hidden } from "@/components/Tools/FormHelperFactory/Configurations/Hidden";
import { image } from "@/components/Tools/FormHelperFactory/Configurations/Image";
import { json } from "@/components/Tools/FormHelperFactory/Configurations/Json";
import { multiSelect } from "@/components/Tools/FormHelperFactory/Configurations/MultiSelect";
import { number } from "@/components/Tools/FormHelperFactory/Configurations/Number";
import { password } from "@/components/Tools/FormHelperFactory/Configurations/Password";
import { range } from "@/components/Tools/FormHelperFactory/Configurations/Range";
import { select } from "@/components/Tools/FormHelperFactory/Configurations/Select";
import { text } from "@/components/Tools/FormHelperFactory/Configurations/Text";
import { textArea } from "@/components/Tools/FormHelperFactory/Configurations/TextArea";
import { textEditor } from "@/components/Tools/FormHelperFactory/Configurations/TextEditor";
import { time } from "@/components/Tools/FormHelperFactory/Configurations/Time";

export const configurations = {
  action,
  alert,
  array,
  checkbox,
  code,
  condition,
  date,
  datetime,
  file,
  filter,
  hidden,
  image,
  json,
  multiSelect,
  number,
  password,
  range,
  select,
  text,
  textArea,
  textEditor,
  time
};

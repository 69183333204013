import {
  base,
  validations
} from "@/components/Tools/FormHelperFactory/Configurations/baseConfigurations";

export const file = {
  ...base,
  ...validations,
  accept: {
    type: "array",
    default: []
  },
  size: {
    type: "number",
    default: 10000
  },
  multiple: {
    type: "checkbox",
    default: false
  }
};

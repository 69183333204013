import _ from "lodash";
import { configurations } from "@/components/Tools/FormHelperFactory/Configurations";
import {
  locatorValidators,
  singleParameterValidators
} from "@/components/Tools/FormHelper/Helper/constants";

export function compressForm(form) {
  let compressedForm = _.cloneDeep(form);
  compressedForm.forEach(field => {
    let defaultConfig = configurations[field.type];
    // Remove default configs from field
    Object.keys(field).forEach(name => {
      if (
        field[name] === "" ||
        _.isEqual(field[name], defaultConfig[name]?.default)
      ) {
        delete field[name];
      }
    });
    if (field.valdations?.length) {
      field.valdations = transformValidations(field.valdations);
    }
  });
  return compressedForm;
}

export function transformValidations(validations) {
  let transformed = {};
  validations.forEach(validation => {
    let validator = validation.name;
    if (
      singleParameterValidators.includes(validator) ||
      locatorValidators.includes(validator)
    ) {
      transformed[validator] = validation.not
        ? { param: validation.param, not: true }
        : validation.param;
    } else {
      transformed[validator] = true;
    }
  });
  return transformed;
}

import {
  base,
  colors
} from "@/components/Tools/FormHelperFactory/Configurations/baseConfigurations";
import i18n from "@/core/plugins/vue-i18n";

export const action = {
  ...base,
  buttonLabel: {
    type: "text",
    required: false,
    default: ""
  },
  color: {
    type: "select",
    options: colors,
    default: "primary"
  },
  action: {
    type: "text",
    help: i18n.t("formHelper.fields.action.actionHelp")
  }
};

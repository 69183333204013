<template>
  <div class="factory-form border rounded p-5 m-n5">
    <FormHelper
      ref="formHelper"
      :key="formHelperKey"
      v-model="values"
      :form="compressedForm"
      :config="options"
      :factory="true"
      @click="onClick"
    />
  </div>
</template>

<script>
import FormHelper from "@/components/Tools/FormHelper/FormHelper.vue";
import { mapActions, mapGetters } from "vuex";
import { SET_ACTIVE_FIELD } from "@/core/services/store/formHelperFactory.module";
import { compressForm } from "@/components/Tools/FormHelperFactory/Helper/functions";

export default {
  components: { FormHelper },
  props: {},
  data() {
    return {
      values: {},
      formHelperKey: 1
    };
  },
  computed: {
    ...mapGetters("formHelperFactory", ["form", "activeFieldId", "options"]),
    compressedForm: function () {
      return compressForm(this.form);
    }
  },
  watch: {
    options: function () {
      this.formHelperKey++;
    }
  },
  mounted() {},
  methods: {
    ...mapActions("formHelperFactory", [SET_ACTIVE_FIELD]),
    onClick(field) {
      let id = field.id === this.activeFieldId ? null : field.id;
      this[SET_ACTIVE_FIELD](id);
    }
  }
};
</script>

<style lang="scss" scoped>
@import "~@/assets/sass/components/_variables.yedi.scss";
.factory-form {
  border-style: dashed !important;
  :deep(.form-helper.factory) {
    .form-field {
      &.active,
      &:hover {
        background-color: $gray-100;
      }
    }
  }
}
</style>

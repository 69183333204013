<template>
  <div class="h-100 d-flex flex-column">
    <div class="h5 font-weight-bolder mb-5">
      {{ $t("formHelperFactory.library") }}
    </div>
    <div class="overflow-y-auto pr-5">
      <draggable
        :list="fieldTypes"
        :group="{ name: 'factory', pull: 'clone', put: false }"
        :sort="false"
        :clone="getDefaultField"
        class="list-group"
      >
        <div
          v-for="(name, i) in fieldTypes"
          :key="i"
          class="list-group-item cursor-move d-flex align-items-center justify-content-between"
        >
          <div>
            {{ $t("formHelperFactory.fieldTypes." + name) }}
          </div>
          <div
            class="btn btn-icon btn-sm text-hover-primary"
            @click="appendField(name)"
          >
            <i class="fal fa-plus" />
          </div>
        </div>
      </draggable>
    </div>
  </div>
</template>

<script>
import { configurations as fields } from "@/components/Tools/FormHelperFactory/Configurations";
import { generateHash } from "@/components/Tools/helperFunctions";
import { mapActions, mapGetters } from "vuex";
import { UPDATE_FORM } from "@/core/services/store/formHelperFactory.module";

export default {
  components: {},
  props: {},
  data() {
    return {
      fields: []
    };
  },
  computed: {
    ...mapGetters("formHelperFactory", ["form"]),
    fieldTypes: function () {
      return Object.keys(fields);
    }
  },
  mounted() {},
  methods: {
    ...mapActions("formHelperFactory", [UPDATE_FORM]),
    getDefaultField(name) {
      let id = generateHash();
      let field = {
        type: name,
        id: id,
        label: this.$t("formHelperFactory.fieldTypes." + name),
        name: name + "-" + id
      };
      let config = fields[name];
      Object.keys(config)
        .filter(configName => !["type", "name", "label"].includes(configName))
        .forEach(configName => {
          field[configName] =
            config[configName].default !== undefined
              ? config[configName].default
              : null;
        });
      return field;
    },
    appendField(name) {
      const field = this.getDefaultField(name);
      let form = this.form;
      form.push(field);
      this[UPDATE_FORM](form);
    }
  }
};
</script>

import {
  base,
  validations
} from "@/components/Tools/FormHelperFactory/Configurations/baseConfigurations";

export const datetime = {
  ...base,
  ...validations,
  format: {
    type: "text",
    strict: "true",
    default: "YYYY-MM-DD HH:mm:ss",
    required: true
  },
  min: {
    type: "text",
    strict: true,
    default: ""
  },
  max: {
    type: "text",
    strict: true,
    default: ""
  },
  range: {
    type: "checkbox",
    default: false
  },
  allowedHours: {
    type: "multiselect",
    options: [...Array(24).keys()],
    default: [...Array(24).keys()],
    clearable: true
  },
  allowedMinutes: {
    type: "multiselect",
    options: [...Array(60).keys()],
    default: [...Array(60).keys()],
    clearable: true
  },
  allowedSeconds: {
    type: "multiselect",
    options: [...Array(60).keys()],
    default: [...Array(60).keys()],
    clearable: true
  },
  useSeconds: {
    type: "checkbox",
    default: false
  }
};

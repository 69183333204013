<template>
  <b-modal
    ref="modalSave"
    hide-footer
    size="xl"
    :title="$t('formHelperFactory.saveForm')"
  >
    <FormHelper ref="form" v-model="value" :form="modalForm" :config="config" />
    <div class="mt-3 text-right">
      <div class="btn btn-primary" @click="save">
        {{ $t("general.save") }}
      </div>
    </div>
  </b-modal>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import FormHelper from "@/components/Tools/FormHelper/FormHelper.vue";
import {
  ADD_PROJECT,
  UPDATE_PROJECT
} from "@/core/services/store/formHelperFactory.module";

export default {
  components: { FormHelper },
  props: {},
  data() {
    return {
      value: {
        project: "",
        name: ""
      },
      config: {
        title: this.$t("formHelperFactory.saveForm")
      }
    };
  },
  computed: {
    ...mapGetters("formHelperFactory", [
      "projects",
      "selectedProject",
      "form",
      "options"
    ]),
    modalForm: function () {
      return [
        {
          type: "select",
          name: "project",
          label: this.$t("formHelperFactory.selectProject"),
          options: this.selectOptions,
          default: this.selectedProject ? this.selectedProject : "new"
        },
        {
          type: "text",
          name: "name",
          label: this.$t("formHelperFactory.newProjectName"),
          strict: true,
          dependsOn: [
            {
              name: "project",
              values: ["new"]
            }
          ],
          validations: {
            required: true,
            sameAs: {
              value: () => "new",
              not: true
            }
          }
        }
      ];
    },
    selectOptions: function () {
      let options = [
        { label: this.$t("formHelperFactory.newProject"), value: "new" }
      ];
      Object.keys(this.projects).forEach(name => {
        options.push({ label: name, value: name });
      });
      return options;
    }
  },
  mounted() {
    this.value = {
      project: "",
      name: ""
    };
  },
  methods: {
    ...mapActions("formHelperFactory", [ADD_PROJECT, UPDATE_PROJECT]),
    show() {
      this.$refs.modalSave.show();
    },
    save() {
      if (this.$refs.form.validate() !== true) {
        this.$toast.fire({
          title: this.$t("formHelperFactory.invalidSave"),
          icon: "warning"
        });
        return;
      }
      const value = {
        form: this.form,
        options: this.options
      };
      if (this.value.project === "new") {
        this[ADD_PROJECT]({ name: this.value.name, value: value });
      } else {
        this[UPDATE_PROJECT]({ name: this.value.project, value: value });
      }
      this.$toast.fire({
        title: this.$t("formHelperFactory.projectSaved"),
        icon: "success"
      });
      this.$refs.modalSave.hide();
    }
  }
};
</script>

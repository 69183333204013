<template>
  <div>
    <div v-if="activeFieldId">
      <b-tabs align="center" content-class="p-5 overflow-y-auto">
        <b-tab
          :title="$t('formHelperFactory.configurationBasic')"
          title-item-class="my-0 mx-1"
        >
          <FormHelper
            v-model="values"
            :form="baseForm"
            :config="{ title: $t('formHelperFactory.configurationBasic') }"
          />
        </b-tab>
        <b-tab
          :title="$t('formHelperFactory.configurationField')"
          title-item-class="my-0 mx-1"
        >
          <FormHelper
            v-model="values"
            :form="fieldForm"
            :config="{ title: $t('formHelperFactory.configurationField') }"
          />
        </b-tab>
        <b-tab
          :title="$t('formHelperFactory.configurationAdvanced')"
          title-item-class="my-0 mx-1"
        >
          <FormHelper
            v-model="values"
            :form="advancedForm"
            :config="{
              title: $t('formHelperFactory.configurationAdvanced')
            }"
          />
        </b-tab>
      </b-tabs>
    </div>
    <div v-else>
      <FormHelper
        v-model="optionsValues"
        :form="optionsForm"
        :config="{ title: $t('formHelperFactory.configurationForm') }"
      />
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { configurations } from "@/components/Tools/FormHelperFactory/Configurations";
import FormHelper from "@/components/Tools/FormHelper/FormHelper.vue";
import {
  UPDATE_FIELD,
  UPDATE_OPTIONS
} from "@/core/services/store/formHelperFactory.module";
import { optionsConfiguration } from "@/components/Tools/FormHelper/FormHelper.vue";

export default {
  components: { FormHelper },
  props: {},
  data() {
    return {
      hiddenFields: ["type"],
      baseFormFields: ["label", "name", "help", "placeholder", "disabled"],
      advancedFormFields: ["required", "validations", "dependsOn"]
    };
  },
  computed: {
    ...mapGetters("formHelperFactory", [
      "form",
      "activeFieldId",
      "activeField",
      "options"
    ]),
    values: {
      get: function () {
        let activeField = this.activeField;

        return activeField;
      },
      set: function (value) {
        this[UPDATE_FIELD]({ id: this.activeFieldId, field: value });
      }
    },
    optionsValues: {
      get: function () {
        return this.options;
      },
      set: function (value) {
        this[UPDATE_OPTIONS](value);
      }
    },
    baseForm: function () {
      let form = [],
        type = this.activeField.type,
        config = configurations[type];
      this.baseFormFields.forEach(name => {
        if (!Object.hasOwn(config, name)) {
          return;
        }
        form.push({ name: name, label: name, ...config[name] });
      });
      return form;
    },
    fieldForm: function () {
      let form = [],
        type = this.activeField.type,
        config = configurations[type];
      Object.keys(config)
        .filter(
          n =>
            !this.hiddenFields.includes(n) &&
            !this.baseFormFields.includes(n) &&
            !this.advancedFormFields.includes(n)
        )
        .forEach(name => {
          form.push({ name: name, label: name, ...config[name] });
        });
      return form;
    },
    advancedForm: function () {
      let form = [],
        type = this.activeField.type,
        config = configurations[type];
      this.advancedFormFields.forEach(name => {
        if (!Object.hasOwn(config, name)) {
          return;
        }
        if (name === "dependsOn") {
          let field = { name: name, label: name, ...config[name] };
          field.fields.find(f => f.name === "name").options = this.form.map(
            f => f.name
          );
          form.push(field);
        } else {
          form.push({ name: name, label: name, ...config[name] });
        }
      });
      return form;
    },
    optionsForm: function () {
      let form = [];
      Object.keys(optionsConfiguration).forEach(name => {
        form.push({ name: name, label: name, ...optionsConfiguration[name] });
      });
      return form;
    }
  },
  mounted() {},
  methods: {
    ...mapActions("formHelperFactory", [UPDATE_FIELD, UPDATE_OPTIONS])
  }
};
</script>

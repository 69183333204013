import {
  base,
  validations
} from "@/components/Tools/FormHelperFactory/Configurations/baseConfigurations";

export const json = {
  ...base,
  ...validations,
  returnJson: {
    type: "checkbox",
    default: false
  },
  fields: {
    type: "group",
    returnJson: false,
    fields: [
      {
        type: "text",
        name: "name",
        label: "name",
        strict: "true",
        default: "",
        required: true
      }
    ],
    default: []
  }
};

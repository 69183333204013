import {
  input,
  validations
} from "@/components/Tools/FormHelperFactory/Configurations/baseConfigurations";

export const text = {
  strict: {
    type: "checkbox",
    default: false
  },
  ...input,
  ...validations
};

import {
  base,
  validations
} from "@/components/Tools/FormHelperFactory/Configurations/baseConfigurations";

export const range = {
  ...base,
  ...validations,
  min: {
    type: "number",
    default: 0
  },
  max: {
    type: "number",
    default: 100
  },
  step: {
    type: "number",
    default: 1
  },
  sliderLabel: {
    type: "text",
    strict: true,
    default: ""
  },
  prependIcon: {
    type: "text",
    strict: true,
    default: ""
  },
  appendIcon: {
    type: "text",
    strict: true,
    default: ""
  }
};

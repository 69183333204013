<template>
  <b-modal
    ref="modalLoad"
    hide-footer
    size="xl"
    :title="$t('formHelperFactory.loadProject')"
  >
    <FormHelper ref="form" v-model="value" :form="modalForm" :config="config" />
    <div class="mt-3 text-right">
      <div
        v-if="value.project !== 'new'"
        class="btn btn-hover-danger text-danger"
        @click="remove"
      >
        {{ $t("general.remove") }}
      </div>
      <div class="btn btn-primary ml-1" @click="load">
        {{ $t("formHelperFactory.load") }}
      </div>
    </div>
  </b-modal>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import FormHelper from "@/components/Tools/FormHelper/FormHelper.vue";
import {
  LOAD_PROJECT,
  NO_PROJECT,
  REMOVE_PROJECT
} from "@/core/services/store/formHelperFactory.module";

export default {
  components: { FormHelper },
  props: {},
  data() {
    return {
      value: {
        project: ""
      },
      config: {
        title: this.$t("formHelperFactory.loadProject")
      }
    };
  },
  computed: {
    ...mapGetters("formHelperFactory", ["projects", "selectedProject", "form"]),
    modalForm: function () {
      return [
        {
          type: "select",
          name: "project",
          label: this.$t("formHelperFactory.selectProject"),
          options: this.options,
          default: "new"
        }
      ];
    },
    options: function () {
      let options = [
        { label: this.$t("formHelperFactory.newEmptyProject"), value: "new" }
      ];
      Object.keys(this.projects).forEach(name => {
        options.push({ label: name, value: name });
      });
      return options;
    }
  },
  mounted() {
    this.value = {
      project: "new"
    };
  },
  methods: {
    ...mapActions("formHelperFactory", [
      NO_PROJECT,
      LOAD_PROJECT,
      REMOVE_PROJECT
    ]),
    show() {
      this.$refs.modalLoad.show();
    },
    load() {
      if (this.value.project === "new") {
        this[NO_PROJECT]();
      } else {
        this[LOAD_PROJECT](this.value.project);
      }
      this.$toast.fire({
        title: this.$t("formHelperFactory.projectLoaded"),
        icon: "success"
      });
      this.$refs.modalLoad.hide();
    },
    remove() {
      if (this.value.project === "new") {
        return;
      }
      this[REMOVE_PROJECT](this.value.project);
      this.value.project = "new";
      this.$toast.fire({
        title: this.$t("formHelperFactory.projectRemoved"),
        icon: "success"
      });
    }
  }
};
</script>

import {
  base,
  validations
} from "@/components/Tools/FormHelperFactory/Configurations/baseConfigurations";

export const date = {
  ...base,
  ...validations,
  format: {
    type: "text",
    strict: "true",
    default: "YYYY-MM-DD",
    required: true
  },
  min: {
    type: "text",
    strict: true,
    default: ""
  },
  max: {
    type: "text",
    strict: true,
    default: ""
  },
  range: {
    type: "checkbox",
    default: false
  }
};

import { render, staticRenderFns } from "./Structure.vue?vue&type=template&id=16fe8f70&scoped=true"
import script from "./Structure.vue?vue&type=script&lang=js"
export * from "./Structure.vue?vue&type=script&lang=js"
import style0 from "./Structure.vue?vue&type=style&index=0&id=16fe8f70&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "16fe8f70",
  null
  
)

export default component.exports
import {
  base,
  select,
  validations
} from "@/components/Tools/FormHelperFactory/Configurations/baseConfigurations";

export const multiSelect = {
  ...base,
  ...select,
  ...validations
};
